import React, { useState } from 'react';
import chevron from './chevron.svg';
import './LanguageSelector.scss';

export default ({
  languages,
  selectedLanguage,
  handleSelectLanguage,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const clickLanguage = (lang) => {
    setIsOpen(false);
    handleSelectLanguage(lang);
  };

  return (
    <div className="language-selector-container">
      <div onClick={() => setIsOpen(!isOpen)} className={`language-selector-control ${className ? className : ''} ${isOpen ? 'open' : ''}`}>
        <div style={{ display: 'flex', gap: '12px' }}>
          {selectedLanguage.locale}
        </div>
        <img className="arrow" src={chevron} />
      </div>
      {
        isOpen ? (
          <div className="options">
            {
              languages.map((lang) => (
                <div onClick={() => clickLanguage(lang)} className="option" key={lang.locale}>
                  {lang.locale}
                </div>
              ))
            }
          </div>
        ) : ''
      }
    </div>
  );
};
