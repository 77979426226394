import React, { useState, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from "react-helmet"
import Container from 'src/components/UI/Container';
import LanguageSelector from 'src/components/UI/LanguageSelector';
import Header from 'src/components/header/Header';
import Footer from 'src/components/footer/Footer';
import ReactMarkdown from 'react-markdown';
import 'src/styles/reset.scss';
import 'src/styles/general.scss';
import './code-of-conduct.scss';

const query = graphql`
  query {
    allCodeOfConductJson {
      nodes {
        content
        id
        lang
        version
      }
    }
  }
`;

const availableLanguages = [
  {
    locale: "en",
    label: "EN",
    description: "English",
  },
  {
    locale: "nl",
    label: "NL",
    description: "Nederlands",
  },
  {
    locale: "pt",
    label: "PT",
    description: "Português",
  },
];

export default () => {
  const [lang, setLang] = useState(availableLanguages[0]);

  const {
    allCodeOfConductJson: { nodes },
  } = useStaticQuery(query);

  const codeOfConductTexts = useMemo(() => {
    return nodes.map((node) => ({ ...node }));
  }, [nodes]);

  const selectedText = useMemo(() => {
    return codeOfConductTexts.find((cocText) => cocText.lang === lang.locale);
  }, [codeOfConductTexts, lang]);

  const changeLanguage = (lang) => {
    setLang(lang);
  };

  return (
    <div className="page code-of-conduct">
      <Header />
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#23356C" />
        <meta
          name="description"
          content="BJJ Gent stands as the pioneer of Brazilian Jiu-Jitsu in Ghent (est. 2008), earning the distinction of being one of Belgium's earliest academies."
        />
        <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;900&display=swap" rel="stylesheet" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet" />
        <title>BJJ Gent | Code of Conduct</title>
      </Helmet>

      <Container className="dark">
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <LanguageSelector
            selectedLanguage={lang}
            languages={availableLanguages}
            handleSelectLanguage={changeLanguage} />
        </div>

        <div className="text-block">
          <ReactMarkdown>
            {selectedText.content}
          </ReactMarkdown>
        </div>
      </Container>
      <Footer />
    </div>
  );
};
